var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "the-app", attrs: { id: "app" } },
    [
      _vm.loading.appHasData
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading.pageShow,
                  expression: "loading.pageShow",
                },
              ],
              staticClass: "the-wrapper",
            },
            [
              _vm.showOpenInApp ? _c(_setup.OpenInApp) : _vm._e(),
              _vm._v(" "),
              _c("router-view", {
                staticClass: "the-header",
                attrs: { name: "header" },
                on: {
                  "toggle-via": function ($event) {
                    return _vm.toggleChat()
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "the-content" }, [_c("router-view")], 1),
              _vm._v(" "),
              _c("router-view", {
                staticClass: "the-footer",
                attrs: { name: "footer" },
                on: {
                  "toggle-via": function ($event) {
                    return _vm.toggleChat()
                  },
                },
              }),
              _vm._v(" "),
              _c(
                _setup.Portal,
                {
                  attrs: {
                    to: "sticky-footer-gdpr",
                    disabled: !["xs", "sm", "md"].includes(
                      _setup.$store.state.ui.client.breakpoint
                    ),
                  },
                },
                [_vm.isGdprVisible ? _c(_setup.NotificationGdpr) : _vm._e()],
                1
              ),
              _vm._v(" "),
              _c(_setup.TheNotifications),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sticky-footer" },
                [
                  _c(_setup.PortalTarget, {
                    attrs: { name: "sticky-footer-add-to-cart" },
                  }),
                  _vm._v(" "),
                  _c(_setup.PortalTarget, {
                    attrs: { name: "sticky-footer-nav" },
                  }),
                  _vm._v(" "),
                  _c(_setup.PortalTarget, {
                    attrs: { name: "sticky-footer-gdpr" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.chat.isAvailable
        ? _c(_setup.Chat, {
            attrs: { show: _vm.chat.show },
            on: {
              "is-loaded": function ($event) {
                return _vm.setInitialChatLoad()
              },
              "close-chat": function ($event) {
                return _vm.toggleChat()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(_setup.TheModals),
      _vm._v(" "),
      _vm.loading.showAppLoader
        ? _c(_setup.TheLoader, { attrs: { "full-page": true } })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading.showPageLoader ? _c(_setup.TheLoaderPage) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }