export default (store, modules) => {
    // recursive function that traverses state
    const statePath = (state, path) => {
        if (path.length) {
            const currentPathKey = path[0];
            return statePath(state[currentPathKey], path.slice(1));
        }

        return state;
    };

    modules.forEach((module) => {
        if (store.hasModule(module.name)) return;

        let preserveState = false;

        if (module.preserveState) {
            const modulePath = Array.isArray(module.preserveState)
                ? module.preserveState
                : module.name;
            preserveState = modulePath.length ? statePath(store.state, modulePath) && true : false;
        }

        store.registerModule(
            module.name,
            module.imported,
            { preserveState },
        );
    });
};
