import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchProductStockService({ $http, params, id }) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}products/${id}/stock`,
        params,
    });

    return {
        status: response.data.status,
        stock: response.data.stock,
    };
}
