import Vue from 'vue';

// plugins
import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';
import VueObserveVisibility from 'vue-observe-visibility';
import PortalVue from 'portal-vue';
// directives
// filters
import { price, uppercase, trim } from './utils/filters';
// components
// other

// custom
import createRouter from './router';
import createStore from './store';
import { createI18n } from './utils/plugins/i18n';
// custom plugins
import emitter from './utils/plugins/emitter';
import isBreakpoint from './utils/plugins/isBreakpoint';
import countries from './utils/plugins/countries';
import months from './utils/plugins/months';
// custom directives
import hoverIntent from './utils/directives/hover-intent';
// custom filters
// custom components
import App from './App.vue';
import BaseIcon from './components/BaseIcon.vue';
import BaseSvg from './components/BaseSvg.vue';
import BaseBtn from './components/BaseBtn.vue';
import BaseImg from './components/BaseImg.vue';
// custom other
import { APP } from './utils/constants';
import { getEnv, http } from './utils';

// plugins
Vue.use(VueObserveVisibility);
Vue.use(emitter);
Vue.use(isBreakpoint);
Vue.use(countries);
Vue.use(months);
Vue.use(PortalVue, { dynamic: true });

// directives
Vue.directive('hover-intent', hoverIntent);

// filters
Vue.filter('price', price);
Vue.filter('uppercase', uppercase);
Vue.filter('trim', trim);

// components
Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseSvg', BaseSvg);
Vue.component('BaseBtn', BaseBtn);
Vue.component('BaseImg', BaseImg);

let store;
let router;
let i18n;
let $http;
let app;
const env = getEnv();

export default async function createApp({
    config,
    authorizationBearer,
    source, platform, lang,
}) {
    store = createStore({ config });
    router = createRouter();
    i18n = createI18n(lang);

    $http = http({
        params: { source, platform, lang },
        other: {
            authorizationBearer,
            debug: ['local', 'development'].includes(env),
            API: { ...config.API },
            PARAMS: { ...config.PARAMS },
        },
    });

    Vue.prototype.$http = $http;
    Vue.prototype.$PARAMS = config.PARAMS;
    Vue.prototype.$API = config.API;
    Vue.prototype.$store = store;

    const unhead = createHead();
    Vue.use(UnheadPlugin);

    app = new Vue({
        store,
        router,
        i18n,
        unhead,
        render: (h) => h(App),
    });

    store.$http = $http;
    store.$set = app.$set;
    store.$delete = app.$delete;

    return {
        Vue,
        app,
        store,
        router,
        i18n,
        unhead,
        params: {
            env,
            app: {
                ssr: false,
                version: APP.VERSION,
            },
        },
    };
}
