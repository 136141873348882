const policyTypes = Object.freeze({
    orderDelivery: 'order-delivery',
    aboutSupplier: 'about-supplier',
    warrantyAndReturn: 'warranty-and-return',
    privacyPolicy: 'privacy-policy',
});

const mapContact = (contacts) => contacts
    .filter((contact) => contact.value
        && !(Array.isArray(contact.value) && contact.value.length === 0));

const mapPolicies = (policies) => policies
    .map((policy) => ({
        icon: policyTypes[policy.type],
        ...policy,
    }))
    .filter((policy) => policy.icon);

export default (payload) => ({
    contact: mapContact(payload.contact),
    policies: mapPolicies(payload.policies),
    ...(payload.rating && { rating: payload.rating }),
});
