import { HTTP_METHOD_TYPE } from '../utils/constants';

export default async function fetchPageSubscribedService({
    $http,
    token,
    params,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.GET,
        url: `${$http.API.V3}user/subscriptions/${token}`,
        params,
    });

    return response.data;
}

export async function setPageSubscribedService({
    $http,
    token,
    params,
    data,
}) {
    const response = await $http({
        method: HTTP_METHOD_TYPE.POST,
        url: `${$http.API.V3}user/subscriptions/${token}`,
        params,
        data,
    });

    return response.data;
}
