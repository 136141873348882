const DOMAINS = {
    'ro.product.dev2.vivre.eu': { id: 1, lang: 'ro', platform: 'vivre' },
    'bg.product.dev2.vivre.eu': { id: 2, lang: 'bg', platform: 'vivre' },
    'hu.product.dev2.vivre.eu': { id: 3, lang: 'hu', platform: 'vivre' },
    'hr.product.dev2.vivre.eu': { id: 4, lang: 'hr', platform: 'vivre' },
    'pl.product.dev2.vivre.eu': { id: 5, lang: 'pl', platform: 'vivre' },
    'eu.product.dev2.vivre.eu': { id: 6, lang: 'en', platform: 'vivre' },
    'si.product.dev2.vivre.eu': { id: 7, lang: 'si', platform: 'vivre' },
    'sk.product.dev2.vivre.eu': { id: 8, lang: 'sk', platform: 'vivre' },
    'cz.product.dev2.vivre.eu': { id: 9, lang: 'cz', platform: 'vivre' },
    'gr.product.dev2.vivre.eu': { id: 10, lang: 'gr', platform: 'vivre' },
    'md.product.dev2.vivre.eu': { id: 11, lang: 'md', platform: 'vivre' },
    'ro.product.dev2.casta.eu': { id: 1, lang: 'ro', platform: 'casta' },
    'bg.product.dev2.casta.eu': { id: 2, lang: 'bg', platform: 'casta' },
    'hu.product.dev2.casta.eu': { id: 3, lang: 'hu', platform: 'casta' },
    'hr.product.dev2.casta.eu': { id: 4, lang: 'hr', platform: 'casta' },
    'pl.product.dev2.casta.eu': { id: 5, lang: 'pl', platform: 'casta' },
    'eu.product.dev2.casta.eu': { id: 6, lang: 'en', platform: 'casta' },
    'si.product.dev2.casta.eu': { id: 7, lang: 'si', platform: 'casta' },
    'sk.product.dev2.casta.eu': { id: 8, lang: 'sk', platform: 'casta' },
    'cz.product.dev2.casta.eu': { id: 9, lang: 'cz', platform: 'casta' },
    'gr.product.dev2.casta.eu': { id: 10, lang: 'gr', platform: 'casta' },
    'md.product.dev2.casta.eu': { id: 11, lang: 'md', platform: 'casta' },
};

const API = {
    BASEURL: 'https://api.dev2.vivre.eu/',
    BASEURL_NODE_API: '//{domain}/api/',
    BASEURL_COURIER: 'https://api.dev2.vivre.eu/courier/v1/',
    BASEURL_CASTA: 'https://api-staging.test.casta.eu/',
};
API.V1 = `${API.BASEURL}mobile_v1/`;
API.V2 = `${API.BASEURL}mobile/v2/`;
API.V3 = `${API.BASEURL}mobile/v3/`;
API.V4 = `${API.BASEURL}mobile/v4/`;

const PARAMS = {
    speedy: {
        token: '?9zEgv/CLpAjqtqy7tk62rB8kOcAGwAcJ6lUCHd2Zbnov5H2ZNkBPaw==',
    },
    adyen: {
        clientKey: 'test_4FHOU2SYMVD2LKFHQKCUUR4CPYBL36D7',
        env: 'test',
        publicKey: '10001|DB07EFD6FF9757DF0FA4BCC3FB6EA246C79069704B47BFBABD19411C16AB8D9724E7D904E6FCD3C9A4A60951A722F445577FA5AF6796062F96C55AB6A5E79578E8199DD570920869291BFC3A016B82183C4E904149EEDD69EF9E2560BA4E9AF4CED5B3990D4E78131A2BE2619193DD52C7F0D55FB343300D26A5AF1FEC7D2872CA64ABADFB4D6AC0C23365DD7C6033DFEED6E2EB9ADD032B7DAA8DE84829ADFF1A63A715A45DD76538B9CC5B208E25A4C0CC0BC52FFB5AE68B65DCCA89F1DF75E71DEAD000987F09D1526C75E61FA4813608C9AA6FAF4ED35D792DDFDB1BFB1696D5AE0B9D8BBE543F25961330A76C8FFFF80052B4222673897DF9210BB30D69',
        merchantId: 'merchant.com.adyen.VIVREDECOTEST',
    },
    casta: {
        apiKey: 'I2ttBtg8lqrxe39PGYq1TBpqiTDusHrfExYgpYxK67ThpeYgjhY5P2OVxph2pPqCBUkTJySCaVQncgOInn9yPg',
        appId: '081f4c94-0524-11ee-a962-42010a54400f',
    },
    google: {
        maps: {
            apiKey: 'AIzaSyC5N1HWwUbtsYqmQ2w45_YQPmw5URBZgDY',
        },
        captcha: {
            siteKey: {
                vivre: '6Lc5ZyIUAAAAAOr1PKLB95X-C_CvjI-4PJeXOZDG',
                casta: '6Ld0fhciAAAAACjh_j9fSrEkqIUhZo-iV0cT5iOV',
            },
        },
    },
    n8n: {
        webhookUrl: 'https://n8n.dev2.vivre.eu/webhook/c7af518f-d0f6-4c29-9797-e00c171f59de/chat',
    },
};

export default {
    DOMAINS,
    API,
    PARAMS,
};
