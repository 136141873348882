<template>
    <div class="notification-gdpr">
        <div class="container-fluid">
            <div class="d-flex flex-row align-items-center">
                <span class="me-auto">{{ $t('cookies_notification_message') }}</span>
                <base-btn
                    variant="outline-light"
                    size="sm"
                    class="btn-edit me-2"
                    @click.native="$emitter.emit('modal', { modal: 'gdpr' })"
                >
                    <span v-html="$t('banner_gdpr_edit')" />
                </base-btn>
                <base-btn
                    variant="primary"
                    size="sm"
                    @click.native="handleGdprForm({ prefs: storeGdprIds , closeModal: false})"
                >
                    <span v-html="$t('landing_yes')" />
                </base-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { gdprMixin } from '../utils/mixins';

export default {
    name: 'NotificationGdpr',

    mixins: [
        gdprMixin,
    ],
};
</script>

<style lang="scss">
@import "../scss/05-components/notification-gdpr";
</style>
