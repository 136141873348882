export const CART_WARNINGS = ['unavailable'];

export const CART_NOTIFICATIONS = ['quantity', 'new_product', 'generic', 'replace_product'];

export const CART_ERRORS = {
    INCOMPATIBLE_PAYMENT_TYPE: 'INCOMPATIBLE_PAYMENT_TYPE',
    INVOICE_NOT_ALLOWED: 'INVOICE_NOT_ALLOWED',
    INVALID_CART: 'INVALID_CART',
    INVALID_PAYMENT_TYPE: 'INVALID_PAYMENT_TYPE',
    CHECKOUT_TRANSACTION_STARTED: 'CHECKOUT_TRANSACTION_STARTED',
};
