import itemCategoryNavigation from './item-category-navigation';

const getHref = (payload) => {
    if (payload.type === 'category') { return payload.slug !== '#' ? `/${payload.slug}` : false; }

    return payload.url !== '' ? payload.url : false;
};

const isAbsoluteUrl = (payload) => payload.indexOf('http') > -1;

const getDomElementTag = (payload) => {
    let domElementTag;

    switch (payload.type) {
    default:
    case 'category':
        domElementTag = 'router-link';
        break;
    case 'link':
        domElementTag = 'div';
        if (payload.url) domElementTag = isAbsoluteUrl(payload.url) ? 'a' : 'router-link';
        break;
    case 'text':
        domElementTag = 'div';
        break;
    case 'tree':
        domElementTag = 'button';
        break;
    case 'slider':
        domElementTag = 'button';
        break;
    }

    if (payload.type === 'category' && payload.slug === '#') { domElementTag = 'div'; }

    return domElementTag;
};

const formatMenuItem = (payload) => ({
    ...itemCategoryNavigation(payload),
    href: getHref(payload),
    domElementTag: getDomElementTag(payload),
    children: payload.children,
    type: payload.type,
});

const childrenMapper = (payload) => {
    for (let i = 0; i < payload.length; i += 1) {
        if (payload[i].children) {
            // recursively overwriting each children array for each children
            payload[i].children = childrenMapper(payload[i].children);
        }
    }

    // the actual mapping of each child
    return payload.map((item) => formatMenuItem(item));
};

const itemBlog = {
    title: 'Blog',
    href: '/blog',
    domElementTag: 'a',
    imgUrl: 'https://s9.vivre.eu/assets/images/blog-app-thumb.png',
    class: 'item-blog',
};

export default (
    payload,
    { showBlog = false } = {},
) => {
    const output = childrenMapper(payload);

    if (showBlog) output.push(itemBlog);

    return output;
};
