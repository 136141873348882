var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notification-gdpr" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-row align-items-center" },
        [
          _c("span", { staticClass: "me-auto" }, [
            _vm._v(_vm._s(_vm.$t("cookies_notification_message"))),
          ]),
          _vm._v(" "),
          _c(
            "base-btn",
            {
              staticClass: "btn-edit me-2",
              attrs: { variant: "outline-light", size: "sm" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emitter.emit("modal", { modal: "gdpr" })
                },
              },
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.$t("banner_gdpr_edit")) },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "base-btn",
            {
              attrs: { variant: "primary", size: "sm" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleGdprForm({
                    prefs: _vm.storeGdprIds,
                    closeModal: false,
                  })
                },
              },
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.$t("landing_yes")) },
              }),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }