<template>
    <div
        id="app"
        class="the-app"
    >
        <div
            v-if="loading.appHasData"
            v-show="loading.pageShow"
            class="the-wrapper"
        >
            <open-in-app v-if="showOpenInApp" />

            <router-view
                name="header"
                class="the-header"
                @toggle-via="toggleChat()"
            />

            <div class="the-content">
                <router-view />
            </div>

            <router-view
                name="footer"
                class="the-footer"
                @toggle-via="toggleChat()"
            />

            <portal
                to="sticky-footer-gdpr"
                :disabled="!['xs', 'sm', 'md'].includes($store.state.ui.client.breakpoint)"
            >
                <notification-gdpr v-if="isGdprVisible" />
            </portal>

            <the-notifications />

            <div class="sticky-footer">
                <portal-target name="sticky-footer-add-to-cart" />
                <portal-target name="sticky-footer-nav" />
                <portal-target name="sticky-footer-gdpr" />
            </div>
        </div>
        <chat
            v-if="chat.isAvailable"
            :show="chat.show"
            @is-loaded="setInitialChatLoad()"
            @close-chat="toggleChat()"
        />
        <the-modals />
        <the-loader
            v-if="loading.showAppLoader"
            :full-page="true"
        />
        <the-loader-page v-if="loading.showPageLoader" />
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useHead } from '@unhead/vue';
import { Portal, PortalTarget } from 'portal-vue';
import cart from './store/modules/cart-mini';
import categories from './store/modules/categories';
import { EVENT, MODAL_USER_TYPES, SOURCE } from './utils/constants';
import { loadLanguageAsync } from './utils/plugins/i18n';
import { appMetaMixin, appMetaSchemaMixin } from './utils/mixins';
import { useCurrentInstance } from './composables/useCurrentInstance';
import TheModals from './components/TheModals.vue';
import NotificationGdpr from './components/NotificationGdpr.vue';
import TheNotifications from './components/TheNotifications.vue';
import TheLoader from './components/TheLoader.vue';
import TheLoaderPage from './components/TheLoaderPage.vue';

const datalayerModule = () => import('./store/modules/datalayer');
const OpenInApp = defineAsyncComponent(() => import('./components/OpenInApp.vue'));
const Chat = defineAsyncComponent(() => import('./components/Chat.vue'));

export default {
    name: 'App',

    components: {
        TheModals,
        NotificationGdpr,
        TheNotifications,
        TheLoader,
        TheLoaderPage,
        OpenInApp,
        Chat,
        Portal,
        PortalTarget,
    },

    mixins: [
        appMetaSchemaMixin,
        appMetaMixin,
    ],

    data() {
        return {
            chat: {
                isAvailable: false,
                show: false,
                isLoaded: false,
            },
        };
    },

    computed: {
        isGdprVisible() {
            let output = false;
            const permittedRoutes = !['NotFound'].includes(this.$route.name);
            const prefsNotSet = typeof this.$store.state.storage.cookies_consent === 'undefined';
            const { isMobileApp } = this.$store.state.storage;

            if (!isMobileApp && permittedRoutes && prefsNotSet) output = true;

            return output;
        },
        isUserLoggedIn() {
            return this.$store.getters['user/isLoggedin'];
        },
        loading() {
            return this.$store.state.ui.loading.web;
        },
        showOpenInApp() {
            let output = true;

            const wasNotificationClosed = this.$store.getters['storage/parsedKeyValue']('notification-open-in-app-closed');
            const { isMobileApp } = this.$store.state.storage;
            const isNotMobileSite = this.$store.state.params.source !== SOURCE.MOBILE;

            const { osName, browserName } = this.$store.state.ui.client;
            const isMobileSafariOnIos = (osName === 'iOS' && browserName === 'Mobile Safari');

            if (
                wasNotificationClosed
                || isMobileApp
                || isNotMobileSite
                || isMobileSafariOnIos
            ) output = false;

            return output;
        },
    },

    watch: {
        $route(route) {
            this.routeReady({ route });

            // fetch data
            this.$store.dispatch('params/fetchParamsApi');
            this.$store.dispatch('shared/components/fetchInlineControl', { topic: this.$route.name });

            loadLanguageAsync({
                $http: this.$http,
                i18nInstance: this.$i18n,
                versionWanted: this.$store.state.params.api.translateVersion,
            });
            this.handlerOpenModal(route);
        },
        '$store.state.ui.client.activityStarted': function onActivityStarted(value) {
            if (value) this.$store.dispatch('ui/client/attachScroll');
            this.$store.dispatch('cns/openCnsConnection');
        },
    },

    beforeDestroy() {
        this.$store.dispatch('cns/closeCnsConnection');
    },

    async serverPrefetch() {
        if (!this.$store.state.params.app.ssr) return;

        await this.fetchData({
            ssr: true,
        });
    },

    mounted() {
        this.$store.dispatch('ui/setLoading', { showAppLoader: true });

        setTimeout(() => {
            this.$store.dispatch('ui/setLoading', {
                showAppLoader: false,
                pageShow: true,
            });
        }, 10000);

        this.broadcastAppMessages();

        this.fetchData({
            csr: true,
        });

        this.$store.dispatch('redirectLogin/removeQueryParams', { route: this.$route, router: this.$router });

        this.$store.dispatch('ui/client/attachActivityStarted');

        this.$store.dispatch('ui/client/attachWindowResizeListener');

        if (this.isUserLoggedIn) {
            window.postMessage(
                {
                    source: SOURCE.SITE,
                    event_type: EVENT.TYPE.USER_LOGGED_IN,
                    data: {
                        userId: this.$store.state.user.uid,
                    },
                },
                '*',
            );
        }

        window.postMessage(
            {
                source: SOURCE.SITE,
                event_type: EVENT.TYPE.STATE,
                data: {
                    countryCode: this.$store.state.params.country.lang,
                },
            },
        );
    },

    methods: {
        async fetchData({ ssr, csr }) {
            await Promise.all([
                this.$route.name !== 'NotFound' && this.$store.dispatch('user/fetchUser'),
                this.$store.dispatch('shared/components/fetchInlineControl', { topic: this.$route.name }),
            ]);

            await this.routeReady({
                route: this.$route,
                csr,
            });

            if (!this.isUserLoggedIn) this.setGuestGdprPreferences();

            if (csr) {
                this.handlerOpenModal(this.$route);
            }

            this.$store.dispatch('ui/setLoading', {
                appHasData: true,
                ...(ssr && { pageShow: true }),
            });

            if (csr) {
                if (this.$store.state.ui.client.activityStarted) {
                    this.initDatalayer();
                } else {
                    await new Promise((resolve) => {
                        const unwatch = this.$watch(
                            () => !!this.$store.state.ui.client.activityStarted,
                            () => {
                                this.initDatalayer();
                                resolve();
                                unwatch();
                            },
                        );
                    });
                }
            }
        },
        async fetchDataHeader({ csr }) {
            if (!this.$store.hasModule(['header', 'cart'])) this.$store.registerModule(['header', 'cart'], cart, { preserveState: this.$store.state.header.cart });
            if (!this.$store.hasModule(['header', 'categories'])) this.$store.registerModule(['header', 'categories'], categories, { preserveState: this.$store.state.header.categories });

            await Promise.all([
                csr && this.$store.dispatch('header/cart/fetchData'),
                this.$store.dispatch('header/categories/fetchData'),
            ]);
        },
        async routeReady({ route, csr }) {
            const pageHasHeader = (route.matched[0].components.header && true) || false;
            if (pageHasHeader) {
                await this.fetchDataHeader({ csr });
            }
        },
        async initDatalayer() {
            const { default: datalayer } = await datalayerModule();
            this.$store.registerModule(['datalayer'], datalayer);

            this.$store.dispatch('datalayer/globalInfo');
            this.$store.dispatch('datalayer/userInfo');
            this.$store.dispatch('datalayer/cookiePreferences', this.$store.state.ui.gdpr);
        },
        isRouteName(name, route = this.$route) {
            return route.name === name;
        },
        setGuestGdprPreferences() {
            const prefs = this.$store.state.storage.cookies_consent;

            if (!prefs) return;

            const cookiesPrefs = prefs.split(',').filter((pref) => !pref.includes('~'));
            this.$store.dispatch('ui/setGdpr', cookiesPrefs, { root: true });
        },
        handlerOpenModal({ hash }) {
            if (hash === '#gdpr') this.openModal({ modal: 'gdpr' });

            if (this.isUserLoggedIn) return;

            switch (hash) {
            case '#login':
                this.openModal({ type: MODAL_USER_TYPES.LOGIN });
                break;
            case '#register':
                this.openModal({ type: MODAL_USER_TYPES.REGISTER });
                break;
            case '#reset-password':
                this.openModal({ type: MODAL_USER_TYPES.RESET_PASSWORD });
                break;
            case '#auto-login':
                this.openModal({ type: MODAL_USER_TYPES.AUTO_LOGIN });
                break;
            default:
                break;
            }
        },
        openModal({ modal = 'user', type }) {
            this.$emitter.emit('modal', { modal, type });
        },
        broadcastAppMessages() {
            window.addEventListener('message', (message) => {
                const event = message.data;
                if (typeof event.source === 'undefined' || event.source === SOURCE.SITE) {
                    return;
                }

                this.$emitter.emit(event.event_type, event);
            }, false);
        },
        toggleChat() {
            if (this.$store.state.params.country.id !== this.$COUNTRY.RO.ID) return;

            if (!this.chat.isLoaded) {
                this.chat.isAvailable = true;
                return;
            }

            this.chat.show = !this.chat.show;
        },
        setInitialChatLoad() {
            this.chat.isLoaded = true;
            this.chat.show = true;
        },
    },
};
</script>

<script setup>
const { $store } = useCurrentInstance();
useHead({
    bodyAttrs: {
        tagDuplicateStrategy: 'replace',
        class: {
            'is-scrolling-up': () => $store.state.ui.client.scrollDirection === 'up',
            'is-scrolling-down': () => $store.state.ui.client.scrollDirection === 'down',
        },
    },
});
</script>

<style lang="scss">
@import "@/scss/main";
@import "@/scss/05-components/app";

.sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
</style>
