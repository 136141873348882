<template>
    <div
        v-if="notifications.length"
        class="the-notifications"
    >
        <template v-for="(notification, index) in notifications">
            <base-modal
                v-if="notification.type === 'modal-alert'"
                :key="`notification-${index}`"
                :visible="notification.visible"
                :title="notification.content.title"
                :variant="notification.variant"
                :hide-backdrop="true"
                modal-class="modal-alert"
                @hidden="close({index})"
                @ok="notification.callback.ok()"
            >
                {{ notification.content.body }}
            </base-modal>
            <base-toast
                v-else-if="notification.type === 'toast'"
                :key="`notification-${index}`"
                :visible="notification.visible"
                :title="notification.content.title"
                :variant="notification.variant"
                :auto-hide="notification.autoHide"
                :position-y="notification.positionY"
                @hidden="close({index})"
            >
                <span v-html="notification.content.body" />
            </base-toast>
        </template>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

const BaseModal = defineAsyncComponent(() => import('./BaseModal.vue'));
const BaseToast = defineAsyncComponent(() => import('./BaseToast.vue'));

export default {
    name: 'TheNotifications',

    components: {
        BaseModal,
        BaseToast,
    },

    computed: {
        isLoggedin() {
            return this.$store.getters['user/isLoggedin'];
        },
        notifications() {
            return this.$store.state.ui.notifications.data;
        },
    },

    methods: {
        close(payload) {
            this.$store.dispatch('ui/notifications/hide', payload);
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/required";

.the-notifications {
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky;
}
</style>
